import { LoadingButton } from "@mui/lab";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import PlusIcon from "@untitled-ui/icons-react/build/esm/Plus";
import { useCallback, useState } from "react";
import { useCreateProcess } from "~/queries/useCreateProcess";
import { useGetProcessesCache } from "~/queries/useGetProcesses";
import type { ChangeEvent } from "react";
export const ProcessAdd = () => {
  const {
    data: processes = []
  } = useGetProcessesCache();
  const createProcess = useCreateProcess();
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    setName(event.target.value);
  }, []);
  const handleAddInit = useCallback((): void => {
    setIsAdding(true);
  }, []);
  const handleAddCancel = useCallback((): void => {
    setIsAdding(false);
    setName("");
  }, []);
  const handleAddConfirm = useCallback(async (): Promise<void> => {
    await createProcess.mutateAsync({
      input: {
        name,
        sortingIndex: processes.length // Add the new process to the end of the list
      }
    });
    setIsAdding(false);
    setName("");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);
  return <Box sx={{
    backgroundColor: theme => theme.palette.mode === "dark" ? "neutral.800" : "neutral.100",
    borderRadius: 2.5,
    mt: 7,
    mx: 1,
    width: {
      sm: 380,
      xs: 300
    }
  }} data-sentry-element="Box" data-sentry-component="ProcessAdd" data-sentry-source-file="process-add.tsx">
			{isAdding ? <Box sx={{
      p: 2
    }}>
					<OutlinedInput autoFocus fullWidth placeholder="My new process" name="name" onChange={handleChange} value={name} sx={{
        "& .MuiInputBase-input": {
          px: 2,
          py: 1
        }
      }} />
					<Box sx={{
        alignItems: "center",
        display: "flex",
        mt: 2
      }}>
						<LoadingButton loading={createProcess.isLoading} onClick={handleAddConfirm} size="small" startIcon={<SvgIcon>
									<PlusIcon />
								</SvgIcon>} variant="contained">
							Create Process
						</LoadingButton>
						<Button disabled={createProcess.isLoading} color="inherit" onClick={handleAddCancel} size="small" sx={{
          ml: 2
        }}>
							Cancel
						</Button>
					</Box>
				</Box> : <Stack alignItems="center" direction="row" onClick={handleAddInit} spacing={1} sx={{
      cursor: "pointer",
      p: 2,
      userSelect: "none"
    }}>
					<SvgIcon color="action">
						<PlusIcon />
					</SvgIcon>
					<Typography color="text.secondary" variant="subtitle1">
						Create Process
					</Typography>
				</Stack>}
		</Box>;
};