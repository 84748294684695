import { useQuery } from "@tanstack/react-query";

import { apiGqlClient } from "~/libs/gql";
import { graphql } from "~/types/__generated/gql";
import { GetAgentsQuery } from "~/types/__generated/gql/graphql";

const query = /* GraphQL */ `
	query GetAgents {
		getAgents {
			_id
			createdAt
			updatedAt
			name
			customFields {
				customField
				textValue
				selectValues
			}
			addresses {
				title
				country
				province
				city
				area
				street
				building
				customFields {
					customField
					textValue
					selectValues
					dateTimeValue
				}
			}
			contacts {
				firstName
				lastName
				email
				telephone
				mobile
				gender
				position
				customFields {
					customField
					textValue
					selectValues
					dateTimeValue
				}
			}
			companyIssuedId
			hiddenProductsConfigs {
				products
				types
				categories
			}
		}
	}
`;

export type GetAgentsResults = GetAgentsQuery["getAgents"];

export const getAgentsQueryKey = () => ["getAgents"];

export const useGetAgents = (enabled?: boolean) => {
	return useQuery({
		enabled: enabled ?? true,
		queryKey: getAgentsQueryKey(),
		queryFn: async () =>
			apiGqlClient.request(graphql(query)).then((res) => res.getAgents),
	});
};

export const useGetAgentsCache = () => {
	return useGetAgents(false);
};
