import { useQuery, useQueryClient } from "@tanstack/react-query";

import { apiGqlClient } from "~/libs/gql";
import { graphql } from "~/types/__generated/gql";
import { GetProcessesQuery } from "~/types/__generated/gql/graphql";
import { sortBySortingIndexAndUpdatedAt } from "~/utils/sort-by-sorting-index-and-updated-at";

const query = /* GraphQL */ `
	query GetProcesses {
		getProcesses {
			_id
			createdAt
			updatedAt
			leadTime
			name
			sortingIndex
			_files {
				_id
				createdAt
				updatedAt
				customFields {
					customField
					textValue
					selectValues
					dateTimeValue
				}
				processPaths {
					process
					leadTime
				}
				processLastUpdatedAt
				isProcessed
				products {
					_id
					createdAt
					updatedAt
					product
					metadata {
						originalPrice
						originalPriceAddons {
							vat {
								percentage
								amount
							}
							serviceCharge {
								percentage
								amount
							}
						}
						listedPrice
					}
					quantity
					options {
						_id
						createdAt
						updatedAt
						option
						metadata {
							originalPrice
							originalPriceAddons {
								vat {
									percentage
									amount
								}
								serviceCharge {
									percentage
									amount
								}
							}
							listedPrice
						}
						quantity

						_option {
							_id
							name
						}
					}

					_product {
						_id
						name
						description
						category
						type
					}
				}
				sortingIndex
				counterConfigs {
					index

					_counter {
						_id
						createdAt
						updatedAt
						prefix
					}
				}
				receipt

				_process {
					_id
					createdAt
					updatedAt
					name
				}
				_user {
					_id
					createdAt
					updatedAt
					firstName
					lastName
					avatar
				}
				_client {
					_id
					createdAt
					updatedAt
					name
				}
				_agent {
					_id
					createdAt
					updatedAt
					name
				}
				_assignees {
					_id
					createdAt
					updatedAt
					firstName
					lastName
					avatar
				}
			}
		}
	}
`;

export type GetProcessesResults = GetProcessesQuery["getProcesses"];

export const getProcessesQueryKey = () => ["getProcesses"];

export const useGetProcesses = (enabled?: boolean) => {
	const queryClient = useQueryClient();

	return useQuery({
		enabled: enabled ?? true,
		queryKey: getProcessesQueryKey(),
		queryFn: async () =>
			apiGqlClient.request(graphql(query)).then((res) => res.getProcesses),
		onSuccess: (data) => {
			queryClient.setQueryData<GetProcessesResults>(
				getProcessesQueryKey(),
				sortProcessesAndFiles(data),
			);
		},
	});
};

export const sortProcessesAndFiles = (array: GetProcessesResults) =>
	array.sort(sortBySortingIndexAndUpdatedAt).map((process) => ({
		...process,
		_files: process._files.sort(sortBySortingIndexAndUpdatedAt),
	}));

export const useGetProcessesCache = () => {
	return useGetProcesses(false);
};
