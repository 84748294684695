import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiGqlClient } from "~/libs/gql";
import { graphql } from "~/types/__generated/gql";
import { UpdateFileMutation, UpdateFileMutationVariables } from "~/types/__generated/gql/graphql";
import { getFileByIdQueryKey } from "../useGetFileById";
import { getProcessesQueryKey } from "../useGetProcesses";
const query = /* GraphQL */`
	mutation UpdateFile($id: ObjectID!, $input: UpdateFileInput!) {
		updateFile(id: $id, input: $input) {
			_id
		}
	}
`;
export type UpdateFileVariables = UpdateFileMutationVariables;
export type UpdateFileResults = UpdateFileMutation["updateFile"];
export const useUpdateFile = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (variables: UpdateFileVariables) => apiGqlClient.request(graphql(query), variables).then(res => res.updateFile),
    onSuccess: ({
      _id
    }) => {
      void queryClient.invalidateQueries(getProcessesQueryKey());
      void queryClient.invalidateQueries(getFileByIdQueryKey(_id));
    }
  });
};