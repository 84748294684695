import { useMemo } from "react";

import { useGetTillProductCategories } from "~/queries/useGetTillProductCategories";
import { useTillSelectedCategoryId } from "~/store";

export const useTillSelectedCategory = () => {
	const selectedCategoryId = useTillSelectedCategoryId();
	const { data: categories = [] } = useGetTillProductCategories();

	return useMemo(
		() => categories.find((c) => c._id === selectedCategoryId),
		[selectedCategoryId, categories],
	);
};
