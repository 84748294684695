import { useQuery } from "@tanstack/react-query";

import { apiGqlClient } from "~/libs/gql";
import { graphql } from "~/types/__generated/gql";
import { GetProductOptionsQuery } from "~/types/__generated/gql/graphql";

const query = /* GraphQL */ `
	query GetProductOptions {
		getProductOptions {
			_id
			createdAt
			updatedAt
			name
			originalPrice
			originalPriceAddons {
				vat {
					percentage
					amount
				}
				serviceCharge {
					percentage
					amount
				}
			}
			listedPrice
		}
	}
`;

export type GetProductOptionsResults =
	GetProductOptionsQuery["getProductOptions"];

export const getProductOptionsQueryKey = () => ["getProductOptions"];

export const useGetProductOptions = (enabled?: boolean) => {
	return useQuery({
		enabled: enabled ?? true,
		queryKey: getProductOptionsQueryKey(),
		queryFn: async () =>
			apiGqlClient.request(graphql(query)).then((res) => res.getProductOptions),
	});
};

export const useGetProductOptionsCache = () => {
	return useGetProductOptions(false);
};
