import { useTheme } from "@mui/material/styles";
import { Document, Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { FC, useMemo } from "react";
import { useTillSummary } from "~/hooks/use-till-summary";
import { $dayjs, DATESTRING_FORMAT } from "~/libs/dayjs";
import { GetCurrentCompanyResults } from "~/queries/useGetCurrentCompany";
import { GetFileByIdResults } from "~/queries/useGetFileById";
const useStyles = () => {
  const theme = useTheme();
  return useMemo(() => {
    return StyleSheet.create({
      page: {
        backgroundColor: "#FFFFFF",
        padding: 24
      },
      h4: {
        fontSize: 14,
        fontWeight: 600,
        lineHeight: 1.235
      },
      h6: {
        fontSize: 12,
        fontWeight: 600,
        lineHeight: 1.6
      },
      alignRight: {
        textAlign: "right"
      },
      subtitle2: {
        fontSize: 10,
        fontWeight: 500,
        lineHeight: 1.57
      },
      body2: {
        fontSize: 10,
        fontWeight: 400,
        lineHeight: 1.43
      },
      gutterBottom: {
        marginBottom: 4
      },
      colorSuccess: {
        color: theme.palette.success.main
      },
      colorError: {
        color: theme.palette.error.main
      },
      uppercase: {
        textTransform: "uppercase"
      },
      header: {
        flexDirection: "row",
        justifyContent: "space-between"
      },
      brand: {
        height: 24,
        width: 24
      },
      company: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 32
      },
      references: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 32
      },
      billing: {
        marginTop: 32
      },
      items: {
        marginTop: 32
      },
      itemRow: {
        borderBottomWidth: 1,
        borderColor: "#EEEEEE",
        borderStyle: "solid",
        flexDirection: "row"
      },
      itemNumber: {
        padding: 6,
        width: "10%"
      },
      itemDescription: {
        padding: 6,
        width: "50%"
      },
      itemQty: {
        padding: 6,
        width: "10%"
      },
      itemUnitAmount: {
        padding: 6,
        width: "15%"
      },
      itemTotalAmount: {
        padding: 6,
        width: "15%"
      },
      summaryRow: {
        flexDirection: "row"
      },
      summaryGap: {
        padding: 6,
        width: "70%"
      },
      summaryTitle: {
        padding: 6,
        width: "15%"
      },
      summaryValue: {
        padding: 6,
        width: "15%"
      },
      notes: {
        marginTop: 32
      }
    });
  }, [theme]);
};
export const InvoicePdfDocument: FC<{
  file: GetFileByIdResults;
  company: GetCurrentCompanyResults;
  itemsSummary: ReturnType<typeof useTillSummary>["updateFileInputSummary"];
}> = ({
  file,
  company,
  itemsSummary
}) => {
  const styles = useStyles();
  return <Document data-sentry-element="Document" data-sentry-component="InvoicePdfDocument" data-sentry-source-file="invoice-pdf-document.tsx">
			<Page size="A4" style={styles.page} data-sentry-element="Page" data-sentry-source-file="invoice-pdf-document.tsx">
				<View style={styles.header} data-sentry-element="View" data-sentry-source-file="invoice-pdf-document.tsx">
					<View data-sentry-element="View" data-sentry-source-file="invoice-pdf-document.tsx">
						<Image
          // src={company.logo!}
          src="/favicon.png" style={styles.brand} data-sentry-element="Image" data-sentry-source-file="invoice-pdf-document.tsx" />
						<Text style={styles.h6} data-sentry-element="Text" data-sentry-source-file="invoice-pdf-document.tsx">{company.name}</Text>
					</View>
					<View data-sentry-element="View" data-sentry-source-file="invoice-pdf-document.tsx">
						<Text style={[styles.h4, styles.uppercase, styles.colorError]} data-sentry-element="Text" data-sentry-source-file="invoice-pdf-document.tsx">
							UNPAID
						</Text>
						<Text style={styles.subtitle2} data-sentry-element="Text" data-sentry-source-file="invoice-pdf-document.tsx">INV-XXXXXX</Text>
					</View>
				</View>
				<View style={styles.company} data-sentry-element="View" data-sentry-source-file="invoice-pdf-document.tsx">
					<View data-sentry-element="View" data-sentry-source-file="invoice-pdf-document.tsx">
						<Text style={[styles.body2, {
            width: "150px"
          }]} data-sentry-element="Text" data-sentry-source-file="invoice-pdf-document.tsx">
							{company.address}
						</Text>
					</View>
					<View data-sentry-element="View" data-sentry-source-file="invoice-pdf-document.tsx">
						<Text style={[styles.subtitle2, styles.gutterBottom]} data-sentry-element="Text" data-sentry-source-file="invoice-pdf-document.tsx">
							Due Date
						</Text>
						<Text style={styles.body2} data-sentry-element="Text" data-sentry-source-file="invoice-pdf-document.tsx">
							{$dayjs().add(99, "y").format(DATESTRING_FORMAT)}
						</Text>
					</View>
					<View data-sentry-element="View" data-sentry-source-file="invoice-pdf-document.tsx">
						<Text style={[styles.subtitle2, styles.gutterBottom]} data-sentry-element="Text" data-sentry-source-file="invoice-pdf-document.tsx">
							Date of issue
						</Text>
						<Text style={styles.body2} data-sentry-element="Text" data-sentry-source-file="invoice-pdf-document.tsx">
							{$dayjs(file.createdAt).format(DATESTRING_FORMAT)}
						</Text>
					</View>
				</View>
				<View style={styles.billing} data-sentry-element="View" data-sentry-source-file="invoice-pdf-document.tsx">
					<Text style={[styles.subtitle2, styles.gutterBottom]} data-sentry-element="Text" data-sentry-source-file="invoice-pdf-document.tsx">Billed to</Text>
					<Text style={styles.body2} data-sentry-element="Text" data-sentry-source-file="invoice-pdf-document.tsx">{file._client.name}</Text>
					<Text style={styles.body2} data-sentry-element="Text" data-sentry-source-file="invoice-pdf-document.tsx">
						{file._client.contacts[0].firstName}&nbsp;
						{file._client.contacts[0].lastName}
					</Text>
					<Text style={styles.body2} data-sentry-element="Text" data-sentry-source-file="invoice-pdf-document.tsx">{file._client.contacts[0].telephone}</Text>
					<Text style={[styles.body2, {
          width: "150px"
        }]} data-sentry-element="Text" data-sentry-source-file="invoice-pdf-document.tsx">
						{[file._client.addresses[0].building, file._client.addresses[0].street, file._client.addresses[0].area, file._client.addresses[0].city, file._client.addresses[0].country].join(", ")}
					</Text>
				</View>
				<View style={styles.items} data-sentry-element="View" data-sentry-source-file="invoice-pdf-document.tsx">
					<View style={styles.itemRow} data-sentry-element="View" data-sentry-source-file="invoice-pdf-document.tsx">
						<View style={styles.itemNumber} data-sentry-element="View" data-sentry-source-file="invoice-pdf-document.tsx">
							<Text style={styles.h6} data-sentry-element="Text" data-sentry-source-file="invoice-pdf-document.tsx">#</Text>
						</View>
						<View style={styles.itemDescription} data-sentry-element="View" data-sentry-source-file="invoice-pdf-document.tsx">
							<Text style={styles.h6} data-sentry-element="Text" data-sentry-source-file="invoice-pdf-document.tsx">Product</Text>
						</View>
						<View style={styles.itemQty} data-sentry-element="View" data-sentry-source-file="invoice-pdf-document.tsx">
							<Text style={styles.h6} data-sentry-element="Text" data-sentry-source-file="invoice-pdf-document.tsx">Qty</Text>
						</View>
						<View style={styles.itemUnitAmount} data-sentry-element="View" data-sentry-source-file="invoice-pdf-document.tsx">
							<Text style={styles.h6} data-sentry-element="Text" data-sentry-source-file="invoice-pdf-document.tsx">Unit Price</Text>
						</View>
						<View style={styles.itemTotalAmount} data-sentry-element="View" data-sentry-source-file="invoice-pdf-document.tsx">
							<Text style={[styles.h6, styles.alignRight]} data-sentry-element="Text" data-sentry-source-file="invoice-pdf-document.tsx">Total</Text>
						</View>
					</View>
					{itemsSummary.items.map((item, index) => {
          return <View key={item.id} style={styles.itemRow}>
								<View style={styles.itemNumber}>
									<Text style={styles.body2}>{index + 1}</Text>
								</View>
								<View style={styles.itemDescription}>
									<Text style={styles.body2}>{item.name}</Text>
									<Text style={styles.body2}>
										{item.options.map(option => option.name).join(", ")}
									</Text>
								</View>
								<View style={styles.itemQty}>
									<Text style={styles.body2}>{item.quantity}</Text>
								</View>
								<View style={styles.itemUnitAmount}>
									<Text style={[styles.body2, styles.alignRight]}>
										{item.subtotal / item.quantity}
									</Text>
								</View>
								<View style={styles.itemTotalAmount}>
									<Text style={[styles.body2, styles.alignRight]}>
										{item.subtotal}
									</Text>
								</View>
							</View>;
        })}
					<View style={styles.summaryRow} data-sentry-element="View" data-sentry-source-file="invoice-pdf-document.tsx">
						<View style={styles.summaryGap} data-sentry-element="View" data-sentry-source-file="invoice-pdf-document.tsx" />
						<View style={styles.summaryTitle} data-sentry-element="View" data-sentry-source-file="invoice-pdf-document.tsx">
							<Text style={styles.body2} data-sentry-element="Text" data-sentry-source-file="invoice-pdf-document.tsx">Subtotal</Text>
						</View>
						<View style={styles.summaryValue} data-sentry-element="View" data-sentry-source-file="invoice-pdf-document.tsx">
							<Text style={[styles.body2, styles.alignRight]} data-sentry-element="Text" data-sentry-source-file="invoice-pdf-document.tsx">
								{itemsSummary.grandTotal}
							</Text>
						</View>
					</View>
					<View style={styles.summaryRow} data-sentry-element="View" data-sentry-source-file="invoice-pdf-document.tsx">
						<View style={styles.summaryGap} data-sentry-element="View" data-sentry-source-file="invoice-pdf-document.tsx" />
						<View style={styles.summaryTitle} data-sentry-element="View" data-sentry-source-file="invoice-pdf-document.tsx">
							<Text style={styles.body2} data-sentry-element="Text" data-sentry-source-file="invoice-pdf-document.tsx">Taxes</Text>
						</View>
						<View style={styles.summaryValue} data-sentry-element="View" data-sentry-source-file="invoice-pdf-document.tsx">
							<Text style={[styles.body2, styles.alignRight]} data-sentry-element="Text" data-sentry-source-file="invoice-pdf-document.tsx">0</Text>
						</View>
					</View>
					<View style={styles.summaryRow} data-sentry-element="View" data-sentry-source-file="invoice-pdf-document.tsx">
						<View style={styles.summaryGap} data-sentry-element="View" data-sentry-source-file="invoice-pdf-document.tsx" />
						<View style={styles.summaryTitle} data-sentry-element="View" data-sentry-source-file="invoice-pdf-document.tsx">
							<Text style={styles.body2} data-sentry-element="Text" data-sentry-source-file="invoice-pdf-document.tsx">Total</Text>
						</View>
						<View style={styles.summaryValue} data-sentry-element="View" data-sentry-source-file="invoice-pdf-document.tsx">
							<Text style={[styles.body2, styles.alignRight]} data-sentry-element="Text" data-sentry-source-file="invoice-pdf-document.tsx">
								{itemsSummary.grandTotal}
							</Text>
						</View>
					</View>
				</View>
				<View style={styles.notes} data-sentry-element="View" data-sentry-source-file="invoice-pdf-document.tsx">
					<Text style={[styles.h6, styles.gutterBottom]} data-sentry-element="Text" data-sentry-source-file="invoice-pdf-document.tsx">Notes</Text>
					<Text style={styles.body2} data-sentry-element="Text" data-sentry-source-file="invoice-pdf-document.tsx">
						Please make sure you have the right bank registration number as I
						had issues before and make sure you guys cover transfer expenses.
					</Text>
				</View>
			</Page>
		</Document>;
};