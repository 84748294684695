import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiGqlClient } from "~/libs/gql";
import { graphql } from "~/types/__generated/gql";
import { PayForFileMutation, PayForFileMutationVariables } from "~/types/__generated/gql/graphql";
import { getFileByIdQueryKey } from "../useGetFileById";
import { getProcessesQueryKey } from "../useGetProcesses";
const query = /* GraphQL */`
	mutation PayForFile($id: ObjectID!, $input: PayForFileInput!) {
		payForFile(id: $id, input: $input) {
			_id
		}
	}
`;
export type PayForFileVariables = PayForFileMutationVariables;
export type PayForFileResults = PayForFileMutation["payForFile"];
export const usePayForFile = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (variables: PayForFileVariables) => apiGqlClient.request(graphql(query), variables).then(res => res.payForFile),
    onSuccess: ({
      _id
    }) => {
      void queryClient.invalidateQueries(getProcessesQueryKey());
      void queryClient.invalidateQueries(getFileByIdQueryKey(_id));
    }
  });
};