import { useQuery } from "@tanstack/react-query";

import { apiGqlClient } from "~/libs/gql";
import { graphql } from "~/types/__generated/gql";
import { GetClientsQuery } from "~/types/__generated/gql/graphql";

const query = /* GraphQL */ `
	query GetClients {
		getClients {
			_id
			createdAt
			updatedAt
			name
			customFields {
				customField
				textValue
				selectValues
			}
			addresses {
				title
				country
				province
				city
				area
				street
				building
				customFields {
					customField
					textValue
					selectValues
					dateTimeValue
				}
			}
			contacts {
				firstName
				lastName
				email
				telephone
				mobile
				gender
				position
				customFields {
					customField
					textValue
					selectValues
					dateTimeValue
				}
			}
			companyIssuedId
			hiddenProductsConfigs {
				products
				types
				categories
			}
		}
	}
`;

export type GetClientsResults = GetClientsQuery["getClients"];

export const getClientsQueryKey = () => ["getClients"];

export const useGetClients = (enabled?: boolean) => {
	return useQuery({
		enabled: enabled ?? true,
		queryKey: getClientsQueryKey(),
		queryFn: async () =>
			apiGqlClient.request(graphql(query)).then((res) => res.getClients),
	});
};

export const useGetClientsCache = () => {
	return useGetClients(false);
};
