import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiGqlClient } from "~/libs/gql";
import { graphql } from "~/types/__generated/gql";
import { BulkUpdateFileInput, BulkUpdateFilesMutation, BulkUpdateFilesMutationVariables } from "~/types/__generated/gql/graphql";
import { getProcessesQueryKey, GetProcessesResults, sortProcessesAndFiles } from "../useGetProcesses";
const query = /* GraphQL */`
	mutation BulkUpdateFiles($inputs: [BulkUpdateFileInput!]!) {
		bulkUpdateFiles(inputs: $inputs)
	}
`;
export type BulkUpdateFilesVariables = BulkUpdateFilesMutationVariables;
export type BulkUpdateFilesResults = BulkUpdateFilesMutation["bulkUpdateFiles"];
export const useBulkUpdateFiles = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (variables: BulkUpdateFilesVariables) => apiGqlClient.request(graphql(query), variables).then(res => res.bulkUpdateFiles),
    onMutate: variables => {
      queryClient.setQueryData<GetProcessesResults>(getProcessesQueryKey(), prev => {
        if (!prev) return [];
        const updatedWithVariables = prev.map(process => {
          return {
            ...process,
            _files: process._files.map(file => {
              const updatedFile = (variables.inputs as BulkUpdateFileInput[]).find(input => input.id === file._id);
              if (updatedFile) {
                return {
                  ...file,
                  sortingIndex: updatedFile.input.sortingIndex ?? file.sortingIndex,
                  _process: updatedFile.input.process && updatedFile.input.process !== file._process._id ? prev.find(p => p._id === updatedFile.input.process)! : file._process
                };
              }
              return file;
            })
          };
        });
        const allFiles = updatedWithVariables.map(process => process._files).flat();
        const updatedProcesses = updatedWithVariables.map(process => {
          return {
            ...process,
            _files: allFiles.filter(file => file._process._id === process._id)
          };
        });
        return sortProcessesAndFiles(updatedProcesses);
      });
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(getProcessesQueryKey());
    }
  });
};