import { useQuery } from "@tanstack/react-query";

import { useAppRouter } from "~/hooks/use-app-router";
import { apiGqlClient } from "~/libs/gql";
import { graphql } from "~/types/__generated/gql";
import { GetFileByIdQuery } from "~/types/__generated/gql/graphql";

const query = /* GraphQL */ `
	query GetFileById($id: ObjectID!) {
		getFileById(id: $id) {
			_id
			createdAt
			updatedAt
			customFields {
				customField
				textValue
				selectValues
				dateTimeValue
			}
			isProcessed
			products {
				_id
				createdAt
				updatedAt
				product
				metadata {
					originalPrice
					originalPriceAddons {
						vat {
							percentage
							amount
						}
						serviceCharge {
							percentage
							amount
						}
					}
					listedPrice
				}
				quantity
				options {
					_id
					createdAt
					updatedAt
					option
					metadata {
						originalPrice
						originalPriceAddons {
							vat {
								percentage
								amount
							}
							serviceCharge {
								percentage
								amount
							}
						}
						listedPrice
					}
					quantity

					_option {
						_id
						name
					}
				}

				_product {
					_id
					name
					description
					category
					type
				}
			}
			sortingIndex
			processPaths {
				leadTime
				_process {
					_id
					createdAt
					updatedAt
					name
					leadTime
				}
			}
			processLastUpdatedAt
			counterConfigs {
				index

				_counter {
					_id
					prefix
				}
			}
			receipt

			_process {
				_id
				createdAt
				updatedAt
				name
			}
			_user {
				_id
				createdAt
				updatedAt
				firstName
				lastName
				avatar
			}
			_client {
				_id
				createdAt
				updatedAt
				name
				addresses {
					title
					country
					province
					city
					area
					street
					building
				}
				contacts {
					firstName
					lastName
					email
					telephone
					mobile
					gender
					position
				}
			}
			_agent {
				_id
				createdAt
				updatedAt
				name
			}
			_assignees {
				_id
				createdAt
				updatedAt
				firstName
				lastName
				avatar
			}
			_receipt {
				_id
				createdAt
				updatedAt
				company
				user
				payments {
					amount
					payment_type
				}
			}
		}
	}
`;

export type GetFileByIdResults = GetFileByIdQuery["getFileById"];

export const getFileByIdQueryKey = (id: string) => ["getFileById", id];

export const useGetFileById = (id?: string, enabled?: boolean) => {
	return useQuery({
		enabled: enabled ?? !!id,
		queryKey: getFileByIdQueryKey(id!),
		queryFn: async () =>
			apiGqlClient
				.request(graphql(query), { id: id! })
				.then((res) => res.getFileById),
	});
};

export const useGetFileByIdCache = (id?: string) => {
	return useGetFileById(id, false);
};

export const useGetCurrentFile = () => {
	const { appQuery } = useAppRouter();
	return useGetFileById(appQuery.fileId);
};

export const useGetCurrentFileCache = () => {
	const { appQuery } = useAppRouter();
	return useGetFileByIdCache(appQuery.fileId);
};
