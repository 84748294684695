import { useMutation, useQueryClient } from "@tanstack/react-query";

import { apiGqlClient } from "~/libs/gql";
import { graphql } from "~/types/__generated/gql";
import {
	CreateProcessMutation,
	CreateProcessMutationVariables,
} from "~/types/__generated/gql/graphql";
import {
	getProcessesQueryKey,
	GetProcessesResults,
	sortProcessesAndFiles,
} from "../useGetProcesses";

const query = /* GraphQL */ `
	mutation CreateProcess($input: CreateProcessInput!) {
		createProcess(input: $input) {
			_id
			createdAt
			updatedAt
			leadTime
			name
			sortingIndex
			_files {
				_id
				createdAt
				updatedAt
				customFields {
					customField
					textValue
					selectValues
					dateTimeValue
				}
				processPaths {
					process
					leadTime
				}
				processLastUpdatedAt
				isProcessed
				products {
					_id
					createdAt
					updatedAt
					product
					metadata {
						originalPrice
						originalPriceAddons {
							vat {
								percentage
								amount
							}
							serviceCharge {
								percentage
								amount
							}
						}
						listedPrice
					}
					quantity
					options {
						_id
						createdAt
						updatedAt
						option
						metadata {
							originalPrice
							originalPriceAddons {
								vat {
									percentage
									amount
								}
								serviceCharge {
									percentage
									amount
								}
							}
							listedPrice
						}
						quantity

						_option {
							_id
							name
						}
					}

					_product {
						_id
						name
						description
						category
						type
					}
				}
				sortingIndex
				counterConfigs {
					index

					_counter {
						_id
						createdAt
						updatedAt
						prefix
					}
				}
				receipt

				_process {
					_id
					createdAt
					updatedAt
					name
				}
				_user {
					_id
					createdAt
					updatedAt
					firstName
					lastName
					avatar
				}
				_client {
					_id
					createdAt
					updatedAt
					name
				}
				_agent {
					_id
					createdAt
					updatedAt
					name
				}
				_assignees {
					_id
					createdAt
					updatedAt
					firstName
					lastName
					avatar
				}
			}
		}
	}
`;

export type CreateProcessVariables = CreateProcessMutationVariables;
export type CreateProcessResults = CreateProcessMutation["createProcess"];

export const useCreateProcess = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (variables: CreateProcessVariables) =>
			apiGqlClient
				.request(graphql(query), variables)
				.then((res) => res.createProcess),
		onSuccess: (data) => {
			queryClient.setQueryData<GetProcessesResults>(
				getProcessesQueryKey(),
				(oldData) =>
					oldData ? sortProcessesAndFiles([...oldData, data]) : [data],
			);
		},
	});
};
