import { LoadingButton } from "@mui/lab";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import ChevronDownIcon from "@untitled-ui/icons-react/build/esm/ChevronDown";
import { useCallback, useEffect, useState } from "react";
import { usePopover } from "~/hooks/use-popover";
import type { FC } from "react";
interface Option {
  label: string;
  value: string;
}
interface FileStatusProps {
  isLoading?: boolean;
  onChange?: (value: string) => void;
  options?: Option[];
  value: string;
  prefix: string;
  shouldDisable?: (option?: Option) => boolean;
}
export const FileStatus: FC<FileStatusProps> = props => {
  const {
    onChange,
    options = [],
    value,
    prefix,
    shouldDisable,
    isLoading
  } = props;
  const popover = usePopover<HTMLDivElement>();
  const [currentOption, setCurrentOption] = useState<Option | undefined>(() => {
    return options.find(option => option.value === value);
  });
  useEffect(() => {
    const option = options.find(option => option.value === value);
    setCurrentOption(option);
  }, [options, value]);
  const handleOptionConfirm = useCallback((): void => {
    if (!currentOption) {
      return;
    }
    onChange?.(currentOption.value);
  }, [currentOption, onChange]);
  const handleOptionSelect = useCallback((value: string): void => {
    const option = options.find(option => option.value === value);
    popover.handleClose();
    setCurrentOption(option);
  }, [options, popover]);
  return <>
			<ButtonGroup ref={popover.anchorRef} variant="contained" data-sentry-element="ButtonGroup" data-sentry-source-file="file-status.tsx">
				<LoadingButton variant="contained" loading={isLoading} disabled={shouldDisable?.(currentOption) ?? false} onClick={handleOptionConfirm} data-sentry-element="LoadingButton" data-sentry-source-file="file-status.tsx">
					{prefix} {currentOption?.label}
				</LoadingButton>
				<Button disabled={isLoading} size="small" onClick={popover.handleToggle} data-sentry-element="Button" data-sentry-source-file="file-status.tsx">
					<ChevronDownIcon data-sentry-element="ChevronDownIcon" data-sentry-source-file="file-status.tsx" />
				</Button>
			</ButtonGroup>
			<Popover anchorEl={popover.anchorRef.current} disableScrollLock onClose={popover.handleClose} open={popover.open} anchorOrigin={{
      horizontal: "right",
      vertical: "bottom"
    }} transformOrigin={{
      horizontal: "right",
      vertical: "top"
    }} data-sentry-element="Popover" data-sentry-source-file="file-status.tsx">
				{options.map(option => <MenuItem key={option.value} selected={option.value === value} onClick={() => handleOptionSelect(option.value)}>
						{option.label}
					</MenuItem>)}
			</Popover>
		</>;
};