import { InputLabel, TextField } from "@mui/material";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Unstable_Grid2";
import { useFormik } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";
import { Dialog } from "~/components/dialog";
import { $dayjs } from "~/libs/dayjs";
import { useCreateProcess } from "~/queries/useCreateProcess";
import { useGetProcesses } from "~/queries/useGetProcesses";
import { useUpdateProcess } from "~/queries/useUpdateProcess";
import type { FC } from "react";
export const leadTimeInputLabels = [{
  field: "years",
  label: "Years"
}, {
  field: "months",
  label: "Months"
}, {
  field: "days",
  label: "Days"
}, {
  field: "hours",
  label: "Hours"
}, {
  field: "minutes",
  label: "Minutes"
}, {
  field: "seconds",
  label: "Seconds"
}];
interface ProcessModalProps {
  processId?: string;
  onClose: () => void;
}
export const leadTimeInputSchema = Yup.object().shape({
  years: Yup.number(),
  months: Yup.number(),
  days: Yup.number(),
  hours: Yup.number(),
  minutes: Yup.number(),
  seconds: Yup.number()
});
const updateProcessSchema = Yup.object().shape({
  name: Yup.string().required(),
  leadTimeInput: leadTimeInputSchema
}).required();
export const ProcessModal: FC<ProcessModalProps> = ({
  processId,
  onClose
}) => {
  const {
    data: processes = []
  } = useGetProcesses();
  const updateProcess = useUpdateProcess();
  const createProcess = useCreateProcess();
  const form = useFormik<Yup.InferType<typeof updateProcessSchema>>({
    initialValues: {
      name: "",
      leadTimeInput: {
        years: 0,
        months: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      }
    },
    validationSchema: updateProcessSchema,
    onSubmit: async ({
      leadTimeInput,
      ...values
    }) => {
      const leadTime = $dayjs.duration(leadTimeInput).toISOString();
      if (processId) {
        await updateProcess.mutateAsync({
          id: processId,
          input: {
            ...values,
            leadTime
          }
        });
      } else {
        await createProcess.mutateAsync({
          input: {
            ...values,
            leadTime,
            sortingIndex: processes.length
          }
        });
      }
      onClose();
    }
  });
  useEffect(() => {
    if (processId) {
      const process = processes.find(process => process._id === processId);
      if (process) {
        const processLeadTime = process.leadTime ? $dayjs.duration(process.leadTime) : undefined;
        void form.setValues({
          name: process.name,
          leadTimeInput: {
            years: processLeadTime ? processLeadTime.years() : 0,
            months: processLeadTime ? processLeadTime.months() : 0,
            days: processLeadTime ? processLeadTime.days() : 0,
            hours: processLeadTime ? processLeadTime.hours() : 0,
            minutes: processLeadTime ? processLeadTime.minutes() : 0,
            seconds: processLeadTime ? processLeadTime.seconds() : 0
          }
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processId]);
  return <Dialog dialogProps={{
    maxWidth: "sm"
  }} title={processId ? "Update process" : "Create process"} cancelButtonProps={{
    disabled: form.isSubmitting,
    onClick: onClose,
    children: "Cancel"
  }} actionButtonProps={{
    children: "Update",
    disabled: !form.isValid,
    loading: form.isSubmitting,
    onClick: () => form.handleSubmit()
  }} content={<Stack mt={0.25} spacing={3} component="form" onSubmit={form.handleSubmit}>
					<TextField autoFocus label="Name" {...form.getFieldProps("name")} error={!!form.errors.name} helperText={form.errors.name} />
					<Stack>
						<InputLabel>Lead Time</InputLabel>
						<Grid container spacing={1}>
							{leadTimeInputLabels.map(({
          field,
          label
        }) => <Grid key={field} xs={4} md={2}>
									<TextField type="number" label={label} {...form.getFieldProps(`leadTimeInput.${field}`)} error={!!form.errors.leadTimeInput?.[field]} helperText={form.errors.leadTimeInput?.[field]} />
								</Grid>)}
						</Grid>
					</Stack>
				</Stack>} data-sentry-element="Dialog" data-sentry-component="ProcessModal" data-sentry-source-file="process-form.tsx" />;
};