import { useQuery } from "@tanstack/react-query";

import { apiGqlClient } from "~/libs/gql";
import { graphql } from "~/types/__generated/gql";
import { GetCountersQuery } from "~/types/__generated/gql/graphql";

const query = /* GraphQL */ `
	query GetCounters {
		getCounters {
			_id
			createdAt
			updatedAt
			prefix
		}
	}
`;

export type GetCountersResults = GetCountersQuery["getCounters"];

export const getCountersQueryKey = () => ["getCounters"];

export const useGetCounters = (enabled?: boolean) => {
	return useQuery({
		enabled: enabled ?? true,
		queryKey: getCountersQueryKey(),
		queryFn: async () =>
			apiGqlClient.request(graphql(query)).then((res) => res.getCounters),
	});
};

export const useGetCountersCache = () => {
	return useGetCounters(false);
};
