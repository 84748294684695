import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiGqlClient } from "~/libs/gql";
import { graphql } from "~/types/__generated/gql";
import { CreateFileMutation, CreateFileMutationVariables } from "~/types/__generated/gql/graphql";
import { getProcessesQueryKey } from "../useGetProcesses";
const query = /* GraphQL */`
	mutation CreateFile($input: CreateFileInput!) {
		createFile(input: $input) {
			_id
		}
	}
`;
export type CreateFileVariables = CreateFileMutationVariables;
export type CreateFileResults = CreateFileMutation["createFile"];
export const useCreateFile = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (variables: CreateFileVariables) => apiGqlClient.request(graphql(query), variables).then(res => res.createFile),
    onSuccess: () => {
      void queryClient.invalidateQueries(getProcessesQueryKey());
    }
  });
};