import { useMemo } from "react";

import { useGetTillProductCategories } from "~/queries/useGetTillProductCategories";
import { useTillTempProductInput } from "~/store";

export const useTillSelectedProduct = () => {
	const tempProductInput = useTillTempProductInput();
	const { data: categories = [] } = useGetTillProductCategories();

	return useMemo(
		() =>
			categories
				.map((c) => c._products)
				.flat()
				.find((c) => c._id === tempProductInput?.product),
		[tempProductInput, categories],
	);
};
