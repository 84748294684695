import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { Seo } from "~/components/seo";
import { Layout as DashboardLayout } from "~/layouts/dashboard";
import { useBulkUpdateFiles } from "~/queries/useBulkUpdateFiles";
import { useBulkUpdateProcesses } from "~/queries/useBulkUpdateProcesses";
import { useGetProcesses } from "~/queries/useGetProcesses";
import { CreateFileModal, ViewFileModal } from "~/sections/file/file-modal";
import { ProcessAdd } from "~/sections/file/process-add";
import { ProcessCard, ProcessLoadingCard } from "~/sections/file/process-card";
import { TillModal } from "~/sections/file/till-modal";
import { Process } from "~/types/__generated/gql/graphql";
import type { NextPage } from "next";
import type { DropResult } from "react-beautiful-dnd";
const Page: NextPage = () => {
  const {
    data: processes = [],
    isLoading
  } = useGetProcesses();
  const bulkUpdateFiles = useBulkUpdateFiles();
  const bulkUpdateProcesses = useBulkUpdateProcesses();
  const handleDragEndFile = async ({
    source,
    destination,
    draggableId: fileId
  }: DropResult): Promise<void> => {
    // Dropped outside the column
    if (!destination) {
      return;
    }

    // File has not been moved
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }
    if (source.droppableId === destination.droppableId) {
      // Same process, different fileing index
      const fileProcess = processes.find(process => process._id === destination.droppableId);
      if (!fileProcess) return;
      const newFiles = Array.from(fileProcess._files);
      const [removed] = newFiles.splice(source.index, 1);
      newFiles.splice(destination.index, 0, removed);
      await bulkUpdateFiles.mutateAsync({
        inputs: newFiles.map((item, index) => ({
          id: item._id,
          input: {
            sortingIndex: index
          }
        }))
      });
    } else {
      // Different process, different fileing index
      const movedFile = processes.map(process => process._files).flat().find(file => file._id === fileId);
      if (!movedFile) return;
      const destinationProcess = processes.find(process => process._id === destination.droppableId);
      if (!destinationProcess) return;
      const newDestinationFiles = Array.from(destinationProcess._files);
      newDestinationFiles.splice(destination.index, 0, movedFile);
      await bulkUpdateFiles.mutateAsync({
        inputs: newDestinationFiles.map((item, index) => ({
          id: item._id,
          input: {
            sortingIndex: index,
            process: item._id === fileId ? destination.droppableId : undefined
          }
        }))
      });
    }
  };
  const handleDragEndProcess = async ({
    source,
    destination
  }: DropResult): Promise<void> => {
    // Dropped outside the column
    if (!destination) {
      return;
    }

    // Process has not been moved
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    // Process moved to a different fileing index
    const newProcesses = Array.from(processes);
    const [removed] = newProcesses.splice(source.index, 1);
    newProcesses.splice(destination.index, 0, removed);
    await bulkUpdateProcesses.mutateAsync({
      inputs: newProcesses.map((item, index) => ({
        id: item._id,
        input: {
          sortingIndex: index
        }
      }))
    });
  };
  const handleDragEnd = (result: DropResult) => {
    const {
      type
    } = result;
    if (type === "FILE") void handleDragEndFile(result);
    if (type === "PROCESS") void handleDragEndProcess(result);
  };
  return <>
			<Seo data-sentry-element="Seo" data-sentry-source-file="index.tsx" />

			<CreateFileModal data-sentry-element="CreateFileModal" data-sentry-source-file="index.tsx" />
			<ViewFileModal data-sentry-element="ViewFileModal" data-sentry-source-file="index.tsx" />
			<TillModal data-sentry-element="TillModal" data-sentry-source-file="index.tsx" />

			<Box component="main" sx={{
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      overflow: "hidden",
      pt: 8
    }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
				<Box sx={{
        px: 3
      }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
					<Typography variant="h4" data-sentry-element="Typography" data-sentry-source-file="index.tsx">Files</Typography>
				</Box>
				<DragDropContext onDragEnd={handleDragEnd} data-sentry-element="DragDropContext" data-sentry-source-file="index.tsx">
					{/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      @ts-ignore */}
					<Box sx={{
          display: "flex",
          flexGrow: 1,
          flexShrink: 1,
          overflowX: "auto",
          overflowY: "hidden",
          px: 3,
          py: 3
        }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
						<Stack direction="row" spacing={3} alignItems="flex-start" data-sentry-element="Stack" data-sentry-source-file="index.tsx">
							<Droppable type="PROCESS" droppableId="process-columns" direction="horizontal" data-sentry-element="Droppable" data-sentry-source-file="index.tsx">
								{/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
         @ts-ignore */}
								{processDroppableProvider => <Stack alignItems="flex-start" direction="row" spacing={3} ref={processDroppableProvider.innerRef}>
										{isLoading ? new Array(5).fill(null).map((_, index) => <ProcessLoadingCard key={index} />) : processes.map(process => <ProcessCard key={process._id} process={process as Process} />)}
										{processDroppableProvider.placeholder}
									</Stack>}
							</Droppable>
							{!isLoading && <ProcessAdd />}
						</Stack>
					</Box>
				</DragDropContext>
			</Box>
		</>;
};
Page.getLayout = page => <DashboardLayout>{page}</DashboardLayout>;
export default Page;