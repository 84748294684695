import { LoadingButton, Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@mui/lab";
import { AppBar, Autocomplete, Button, Card, CardActions, CardContent, CardHeader, Chip, Dialog, FormHelperText, MenuItem, OutlinedInput, Skeleton, TextField, Toolbar } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { Printer } from "@untitled-ui/icons-react";
import { BarChartSquarePlus, Check, ChevronRight, Pencil01, PiggyBank01, Route, Trash01, X } from "@untitled-ui/icons-react/build/esm";
import XIcon from "@untitled-ui/icons-react/build/esm/X";
import { useFormik } from "formik";
import { pick } from "lodash";
import { useRouter } from "next/router";
import { FC, useEffect, useMemo, useState } from "react";
import { useBoolean } from "usehooks-ts";
import * as Yup from "yup";
import { CustomFieldRenderer, CustomFieldsDialog } from "~/components/custom-fields-dialog";
import { ProductsTableSummary } from "~/components/products-table-summary";
import { useAppRouter } from "~/hooks/use-app-router";
import { useTillSummary } from "~/hooks/use-till-summary";
import { $dayjs } from "~/libs/dayjs";
import { useGetAgents } from "~/queries/useGetAgents";
import { useGetClients } from "~/queries/useGetClients";
import { useGetCounters } from "~/queries/useGetCounters";
import { useGetCurrentCompany } from "~/queries/useGetCurrentCompany";
import { useGetFileCustomFields } from "~/queries/useGetCustomFields";
import { useGetCurrentFile } from "~/queries/useGetFileById";
import { useGetProcessesCache } from "~/queries/useGetProcesses";
import { useGetUsers } from "~/queries/useGetUsers";
import { usePayForFile } from "~/queries/usePayForFile";
import { useUpdateFile } from "~/queries/useUpdateFile";
import { useResetTill, useSetTillIsOpen, useTill, useUpdateTillUpdateFileInput } from "~/store";
import { CUSTOM_FIELD_MODEL, CustomFieldValueInput, PayForFileMutationVariables, UpdateFileInput } from "~/types/__generated/gql/graphql";
import { DefineProcessPathsDialog } from "./define-process-paths-dialog";
import { FileStatus } from "./file-status";
import { InvoicePdfDialog } from "./invoice-pdf-dialog";
export const ViewFileModal = () => {
  const {
    currentActions: {
      isViewingFile
    },
    router
  } = useAppRouter();
  const {
    data: processes = []
  } = useGetProcessesCache();
  const {
    data: file,
    isLoading
  } = useGetCurrentFile();
  const till = useTill();
  const setTillIsOpen = useSetTillIsOpen();
  const resetTill = useResetTill();
  const updateUpdateFileInput = useUpdateTillUpdateFileInput();
  const {
    data: agents = []
  } = useGetAgents();
  const {
    data: clients = []
  } = useGetClients();
  const {
    data: users = []
  } = useGetUsers();
  const {
    data: counters = []
  } = useGetCounters();
  const {
    updateFileInputSummary
  } = useTillSummary();
  const updateFile = useUpdateFile();
  const {
    value: isProcessPathsDialogOpen,
    setTrue: openProcessPathsDialog,
    setFalse: closeProcessPathsDialog
  } = useBoolean(false);
  const {
    value: isPaymentDialogOpen,
    setTrue: openPaymentDialog,
    setFalse: closePaymentDialog
  } = useBoolean(false);
  const {
    value: isFileMoving,
    toggle: toggleFileMoving
  } = useBoolean(false);
  const {
    value: isFileProcessing,
    toggle: toggleFileProcessing
  } = useBoolean(false);
  const {
    value: isCustomFieldsDialogOpen,
    setTrue: openCustomFieldsDialog,
    setFalse: closeCustomFieldsDialog
  } = useBoolean(false);
  const {
    data: customFields = []
  } = useGetFileCustomFields();
  const [customFieldValues, setCustomFieldValues] = useState<Record<string, CustomFieldValueInput>>({});
  const nextProcessId = useMemo(() => {
    if (!file?.processPaths.length) return;
    const currentProcessIndex = file.processPaths.findIndex(path => path._process._id === file._process._id);
    return file.processPaths[currentProcessIndex + 1]?._process?._id;
  }, [file]);
  const processOptions = useMemo(() => processes.map(column => ({
    label: column.name,
    value: column._id
  })), [processes]);
  const onClose = router.back;
  const handleMoveFileToColumn = async (columnId: string): Promise<void> => {
    try {
      toggleFileMoving();
      await updateFile.mutateAsync({
        id: file!._id,
        input: {
          process: columnId
        }
      });
      toggleFileMoving();
      onClose();
    } catch (_) {
      toggleFileMoving();
    }
  };
  const handleFileProcessedStatusChanged = async (): Promise<void> => {
    try {
      toggleFileProcessing();
      await updateFile.mutateAsync({
        id: file!._id,
        input: {
          isProcessed: !file!.isProcessed
        }
      });
      toggleFileProcessing();
    } catch (_) {
      toggleFileProcessing();
    }
  };
  useEffect(() => {
    if (isViewingFile && file) {
      const input: UpdateFileInput = {
        // skip null check to allow for unassigning agent
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        agent: file._agent?._id ?? null,
        client: file._client._id,
        isProcessed: file.isProcessed,
        process: file._process._id,
        products: file.products.map(product => ({
          ...pick(product, ["_id", "metadata", "product", "quantity"]),
          options: product.options.map(option => pick(option, ["_id", "metadata", "option", "quantity"]))
        })) as UpdateFileInput["products"],
        assignees: file._assignees.map(assignee => assignee._id),
        processPaths: file.processPaths.map(processPath => ({
          process: processPath._process._id,
          leadTime: processPath.leadTime
        })) as UpdateFileInput["processPaths"],
        counterConfigs: file.counterConfigs ? {
          counter: file.counterConfigs._counter._id
        } : undefined
      };
      updateUpdateFileInput(input);
      if (file.customFields) {
        setCustomFieldValues(file.customFields.reduce((acc, field) => ({
          ...acc,
          [field.customField]: field
        }), {}));
      }
    }
    return () => {
      if (isViewingFile) resetTill();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isViewingFile, file]);
  const {
    value: isInvoiceDialogOpen,
    setTrue: openInvoiceDialog,
    setFalse: closeInvoiceDialog
  } = useBoolean(false);
  return <Dialog fullWidth maxWidth="xl" scroll="paper" open={isViewingFile} data-sentry-element="Dialog" data-sentry-component="ViewFileModal" data-sentry-source-file="view-file-modal.tsx">
			{isCustomFieldsDialogOpen && <CustomFieldsDialog model={CUSTOM_FIELD_MODEL.FILE} onClose={closeCustomFieldsDialog} />}
			{isPaymentDialogOpen && <PaymentDialog onClose={closePaymentDialog} />}
			{isInvoiceDialogOpen && <InvoicePdfDialog onClose={closeInvoiceDialog} />}
			<Stack gap={3} p={3} sx={{
      height: "100dvh",
      overflow: "auto"
    }} data-sentry-element="Stack" data-sentry-source-file="view-file-modal.tsx">
				{isLoading ? <>
						<Stack direction="row" justifyContent="space-between" spacing={1}>
							<Skeleton variant="rounded" height={40} sx={{
            flex: 1
          }} />
							<Skeleton variant="circular" height={40} width={40} />
						</Stack>
						<Box>
							<Skeleton variant="rounded" height={40} />
						</Box>
						<Box>
							<Grid container spacing={3}>
								<Grid xs={12} sm={4}>
									<Skeleton variant="rounded" height={40} width={120} />
								</Grid>
								<Grid xs={12} sm={8}>
									<Skeleton variant="rounded" height={40} sx={{
                flex: 1
              }} />
								</Grid>
								<Grid xs={12} sm={4}>
									<Skeleton variant="rounded" height={40} width={120} />
								</Grid>
								<Grid xs={12} sm={8}>
									<Skeleton variant="circular" height={40} width={40} />
								</Grid>
								<Grid xs={12} sm={4}>
									<Skeleton variant="rounded" height={40} width={120} />
								</Grid>
								<Grid xs={12} sm={8}>
									<Skeleton variant="rounded" height={40} sx={{
                flex: 1
              }} />
								</Grid>
								<Grid xs={12} sm={4}>
									<Skeleton variant="rounded" height={40} width={120} />
								</Grid>
								<Grid xs={12} sm={8}>
									<Skeleton variant="rounded" height={40} sx={{
                flex: 1
              }} />
								</Grid>
								<Grid xs={12} sm={4}>
									<Skeleton variant="rounded" height={40} width={120} />
								</Grid>
								<Grid xs={12} sm={8}>
									<Skeleton variant="rounded" height={40} sx={{
                flex: 1
              }} />
								</Grid>
								<Grid xs={12} sm={4}>
									<Skeleton variant="rounded" height={40} width={120} />
								</Grid>
								<Grid xs={12} sm={8}>
									<Skeleton variant="rounded" height={40} sx={{
                flex: 1
              }} />
								</Grid>
								<Grid xs={12}>
									<Skeleton variant="rounded" height={240} sx={{
                flex: 1
              }} />
								</Grid>
							</Grid>
						</Box>
					</> : <>
						{isProcessPathsDialogOpen && <DefineProcessPathsDialog initialValues={till.updateFileInput?.processPaths ?? []} onDone={async values => {
          await updateFile.mutateAsync({
            id: file!._id,
            input: {
              processPaths: values as UpdateFileInput["processPaths"]
            }
          });
          updateUpdateFileInput({
            processPaths: values as UpdateFileInput["processPaths"]
          });
          closeProcessPathsDialog();
        }} onCancel={closeProcessPathsDialog} />}
						<Stack alignItems={{
          sm: "center"
        }} direction={{
          xs: "column-reverse",
          sm: "row"
        }} justifyContent={{
          sm: "space-between"
        }} spacing={1}>
							<div>
								{!file?.processPaths?.length && <FileStatus prefix="Move to" onChange={handleMoveFileToColumn} isLoading={isFileMoving} options={processOptions} value={file?._process?._id ?? processOptions[0]?.value} />}
								{!!file?.processPaths?.length && <LoadingButton variant="contained" endIcon={<SvgIcon>
												<ChevronRight />
											</SvgIcon>} disabled={!nextProcessId} loading={isFileMoving}
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
            onClick={() => handleMoveFileToColumn(nextProcessId!)}>
										Move to next process
									</LoadingButton>}
							</div>
							<Stack justifyContent="flex-end" alignItems="center" direction="row" spacing={1}>
								<LoadingButton color="success" variant={file?.isProcessed ? "contained" : "outlined"} startIcon={file?.isProcessed ? <SvgIcon>
												<Check />
											</SvgIcon> : null} loading={isFileProcessing} onClick={handleFileProcessedStatusChanged}>
									{file?.isProcessed ? "Processed" : "Mark processed"}
								</LoadingButton>

								<IconButton onClick={onClose}>
									<SvgIcon>
										<XIcon />
									</SvgIcon>
								</IconButton>
							</Stack>
						</Stack>
						<Box>
							<Stack spacing={2} direction="row">
								<TextField select label="Prefix" sx={{
              width: "150px"
            }} value={file?.counterConfigs?._counter?._id ?? ""} onChange={async e => {
              await updateFile.mutateAsync({
                id: file!._id,
                input: {
                  counterConfigs: {
                    counter: e.target.value
                  }
                }
              });
              updateUpdateFileInput({
                counterConfigs: {
                  counter: e.target.value
                }
              });
            }}>
									{counters.map(counter => <MenuItem key={counter._id} value={counter._id}>
											{counter.prefix}
										</MenuItem>)}
								</TextField>
								<OutlinedInput disabled fullWidth value={file?.counterConfigs?.index ?? ""} placeholder="Prefix index goes here" />
							</Stack>
							<FormHelperText>
								Changing prefix will update the current prefix index
							</FormHelperText>
							<Divider />
						</Box>
						<Grid container spacing={3}>
							<Grid container spacing={3} xs={12} md={6} height="fit-content">
								<Grid xs={12} sm={4}>
									<Typography color="text.secondary" variant="caption">
										Last updated
									</Typography>
								</Grid>
								<Grid xs={12} sm={8}>
									<Typography variant="caption">
										{updateFile.isLoading ? "Saving..." : $dayjs(file?.updatedAt).fromNow()}
									</Typography>
								</Grid>
								<Grid xs={12} sm={4}>
									<Typography color="text.secondary" variant="caption">
										Created by
									</Typography>
								</Grid>
								<Grid xs={12} sm={8}>
									<Stack direction="row" alignItems="center" spacing={1}>
										<Avatar src={file?._user?.avatar ?? undefined} />
										<Stack>
											<Typography component="div" variant="caption">
												{file?._user?.firstName ?? "N/A"}
											</Typography>
											<Typography variant="caption" color="text.secondary">
												<i>{$dayjs(file?.createdAt).fromNow()}</i>
											</Typography>
										</Stack>
									</Stack>
								</Grid>
								<Grid xs={12} sm={4}>
									<Typography color="text.secondary" variant="caption">
										Process paths
									</Typography>
								</Grid>
								<Grid xs={12} sm={8}>
									{!!file?.processPaths?.length && <Timeline sx={{
                m: 0,
                py: 0
              }}>
											{file.processPaths.slice(0, 3).map((path, index, current) => {
                  return <TimelineItem key={index} sx={{
                    "::before": {
                      content: "unset"
                    }
                  }}>
															<TimelineSeparator>
																<TimelineDot color={path._process._id === file?._process._id ? "primary" : "grey"} />
																{index < 2 && !!current[index + 1] && <TimelineConnector />}
																{index === 2 && !!file.processPaths[3] && <TimelineConnector />}
															</TimelineSeparator>
															<TimelineContent>
																{path._process.name}
															</TimelineContent>
														</TimelineItem>;
                })}
										</Timeline>}
									<Button startIcon={<SvgIcon>
												<Route />
											</SvgIcon>} onClick={openProcessPathsDialog}>
										Define process paths
									</Button>
								</Grid>
								<Grid xs={12} sm={4}>
									<Typography color="text.secondary" variant="caption">
										Assignees
									</Typography>
								</Grid>
								<Grid xs={12} sm={8}>
									<Autocomplete multiple fullWidth disableCloseOnSelect options={users} value={users.filter(user => till.updateFileInput?.assignees?.includes(user._id))} isOptionEqualToValue={(option, value) => option._id === value._id} getOptionLabel={option => option.firstName} onChange={async (_, values) => {
                await updateFile.mutateAsync({
                  id: file!._id,
                  input: {
                    assignees: values.map(user => user._id)
                  }
                });
                updateUpdateFileInput({
                  assignees: values.map(user => user._id)
                });
              }} renderInput={params => <TextField {...params} label="Users" />} renderTags={(value, getTagProps) => value.map((value, index) => <Chip {...getTagProps({
                index
              })} key={value._id} label={value.firstName} avatar={<Avatar src={value.avatar ?? undefined} />} />)} renderOption={(props, option) => <li {...props}>
												<Avatar src={option.avatar ?? undefined} />
												&nbsp;{option.firstName}
											</li>} />
								</Grid>
								<Grid xs={12} sm={4}>
									<Typography color="text.secondary" variant="caption">
										Client
									</Typography>
								</Grid>
								<Grid xs={12} sm={8}>
									<Autocomplete fullWidth options={clients} value={clients.find(client => client._id === till.updateFileInput?.client)} isOptionEqualToValue={(option, value) => option._id === value?._id} getOptionLabel={option => option.name} onChange={async (_, value) => {
                await updateFile.mutateAsync({
                  id: file!._id,
                  input: {
                    client: value?._id
                  }
                });
                updateUpdateFileInput({
                  client: value?._id
                });
              }} renderInput={params => <TextField {...params} label="Clients" />} />
								</Grid>
								<Grid xs={12} sm={4}>
									<Typography color="text.secondary" variant="caption">
										Agent
									</Typography>
								</Grid>
								<Grid xs={12} sm={8}>
									<Autocomplete fullWidth options={agents} value={agents.find(agent => agent._id === till.updateFileInput?.agent)} isOptionEqualToValue={(option, value) => option._id === value?._id} getOptionLabel={option => option.name} onChange={async (_, value) => {
                await updateFile.mutateAsync({
                  id: file!._id,
                  input: {
                    // skip null check to allow for unassigning agent
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    agent: value?._id ?? null
                  }
                });
                updateUpdateFileInput({
                  agent: value?._id
                });
              }} renderInput={params => <TextField {...params} label="Agents" />} />
								</Grid>
								{customFields.map(field => [<Grid xs={12} sm={4} key={`${field._id}-label`}>
										<Typography color="text.secondary" variant="caption">
											{field.name}
										</Typography>
									</Grid>, <Grid xs={12} sm={8} key={`${field._id}-input`}>
										<CustomFieldRenderer field={field} value={customFieldValues[field._id]} onChange={async value => {
                const updatedCustomFieldValues = {
                  ...customFieldValues,
                  [value.customField]: value
                };
                await updateFile.mutateAsync({
                  id: file!._id,
                  input: {
                    customFields: Object.values(updatedCustomFieldValues)
                  }
                });
                setCustomFieldValues(updatedCustomFieldValues);
              }} />
									</Grid>])}
								<Grid xs={12} textAlign="right">
									<Button startIcon={<BarChartSquarePlus />} onClick={openCustomFieldsDialog}>
										Manage custom fields
									</Button>
								</Grid>
							</Grid>
							<Grid container spacing={3} xs={12} md={6} flexDirection="column">
								<Grid xs={12} textAlign="right">
									<Button onClick={() => {
                setTillIsOpen(true);
              }} startIcon={<SvgIcon>
												<Pencil01 />
											</SvgIcon>}>
										Update products
									</Button>
								</Grid>
								<Grid xs={12}>
									<ProductsTableSummary items={updateFileInputSummary.items} grandTotal={updateFileInputSummary.grandTotal} />
									<Grid xs={12} mb={2}>
										<Stack direction="row" gap={1} justifyContent="flex-end">
											<Button startIcon={<SvgIcon>
														<Printer />
													</SvgIcon>} onClick={() => openInvoiceDialog()}>
												Invoice
											</Button>
										</Stack>
									</Grid>
									<Grid xs={12} textAlign="right">
										{file?.receipt ? <Chip sx={{
                  ".MuiChip-icon": {
                    color: "inherit"
                  }
                }} color="success" icon={<SvgIcon>
														<PiggyBank01 />
													</SvgIcon>} label="Paid" /> : <Button startIcon={<SvgIcon>
														<PiggyBank01 />
													</SvgIcon>} onClick={() => openPaymentDialog()}>
												Take payment
											</Button>}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</>}
			</Stack>
		</Dialog>;
};
const PaymentDialog: FC<{
  onClose: () => void;
}> = ({
  onClose
}) => {
  const {
    query
  } = useRouter();
  const {
    data
  } = useGetCurrentCompany();
  const payForFile = usePayForFile();
  const {
    updateFileInputSummary
  } = useTillSummary();
  const form = useFormik<PayForFileMutationVariables>({
    validateOnMount: true,
    initialValues: {
      id: String(query.fileId),
      input: {
        payments: []
      }
    },
    validationSchema: Yup.object({
      id: Yup.string().required("Required"),
      input: Yup.object({
        payments: Yup.array().of(Yup.object({
          payment_type: Yup.string().required("Required"),
          amount: Yup.number().required("Required")
        }).required("Required")).required("Required")
      })
    }),
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSubmit: () => {}
  });
  return <Dialog open fullWidth maxWidth="md" data-sentry-element="Dialog" data-sentry-component="PaymentDialog" data-sentry-source-file="view-file-modal.tsx">
			<Stack sx={{
      height: "100dvh",
      overflow: "auto"
    }} data-sentry-element="Stack" data-sentry-source-file="view-file-modal.tsx">
				<AppBar color="inherit" sx={{
        position: "relative"
      }} data-sentry-element="AppBar" data-sentry-source-file="view-file-modal.tsx">
					<Toolbar data-sentry-element="Toolbar" data-sentry-source-file="view-file-modal.tsx">
						<Typography sx={{
            ml: 2,
            flex: 1
          }} variant="h6" component="div" data-sentry-element="Typography" data-sentry-source-file="view-file-modal.tsx">
							Taking payment
						</Typography>

						<LoadingButton variant="contained" startIcon={<SvgIcon>
									<PiggyBank01 />
								</SvgIcon>} disabled={!form.isValid} loading={payForFile.isLoading} onClick={async () => {
            await payForFile.mutateAsync(form.values);
            onClose();
          }} data-sentry-element="LoadingButton" data-sentry-source-file="view-file-modal.tsx">
							Complete payment
						</LoadingButton>

						<IconButton sx={{
            ml: 1
          }} onClick={onClose} data-sentry-element="IconButton" data-sentry-source-file="view-file-modal.tsx">
							<SvgIcon data-sentry-element="SvgIcon" data-sentry-source-file="view-file-modal.tsx">
								<X data-sentry-element="X" data-sentry-source-file="view-file-modal.tsx" />
							</SvgIcon>
						</IconButton>
					</Toolbar>
				</AppBar>
				<Grid container spacing={3} data-sentry-element="Grid" data-sentry-source-file="view-file-modal.tsx">
					<Grid xs={12} md={6} container spacing={3} p={3} data-sentry-element="Grid" data-sentry-source-file="view-file-modal.tsx">
						{(data?.paymentTypes ?? []).map(pt => <Grid key={pt._id} xs={6} md={4}>
								<Card variant="outlined" sx={[{
              height: "150px",
              cursor: "pointer",
              ":hover": {
                boxShadow: t => t.shadows[8]
              }
            }, form.values.input.payments.some(p => p.payment_type === pt._id) && {
              boxShadow: t => t.shadows[8],
              borderColor: "primary.main",
              backgroundColor: "primary.main",
              color: t => t.palette.primary.contrastText,
              ".MuiCardHeader-subheader": {
                color: t => t.palette.primary.contrastText
              }
            }]} onClick={() => {
              if (!form.values.input.payments.some(p => p.payment_type === pt._id)) {
                void form.setFieldValue("input.payments", [...form.values.input.payments, {
                  payment_type: pt._id,
                  amount: updateFileInputSummary.grandTotal - form.values.input.payments.reduce((acc, cur) => acc + cur.amount, 0)
                }]);
              }
            }}>
									<CardHeader title={pt.name} />
									<CardContent />
									<CardActions />
								</Card>
							</Grid>)}
					</Grid>
					<Grid xs={12} md={6} container spacing={3} p={3} data-sentry-element="Grid" data-sentry-source-file="view-file-modal.tsx">
						<Grid xs={12} data-sentry-element="Grid" data-sentry-source-file="view-file-modal.tsx">
							<Stack spacing={3} data-sentry-element="Stack" data-sentry-source-file="view-file-modal.tsx">
								<Divider data-sentry-element="Divider" data-sentry-source-file="view-file-modal.tsx">
									<Typography variant="body2" data-sentry-element="Typography" data-sentry-source-file="view-file-modal.tsx">
										{" "}
										Selected payment types
									</Typography>
								</Divider>
								{form.values.input.payments.map((p, i) => <Stack key={`${p.payment_type}-selected`} direction="row" spacing={1}>
										<div>
											<IconButton sx={{
                    mt: 1
                  }} color="error" onClick={() => {
                    void form.setFieldValue(`input.payments`, form.values.input.payments.filter(payment => payment.payment_type !== p.payment_type));
                  }}>
												<Trash01 />
											</IconButton>
										</div>
										<TextField type="number" sx={{
                  flex: 1
                }} label={data?.paymentTypes.find(t => t._id === p.payment_type)?.name} {...form.getFieldProps(`input.payments[${i}].amount`)}
                // @ts-ignore
                error={!!form.errors.input?.payments?.[i]?.amount}
                // @ts-ignore
                helperText={form.errors.input?.payments?.[i]?.amount} />
									</Stack>)}
							</Stack>
						</Grid>
					</Grid>
				</Grid>
			</Stack>
		</Dialog>;
};