import { useMutation, useQueryClient } from "@tanstack/react-query";

import { apiGqlClient } from "~/libs/gql";
import { graphql } from "~/types/__generated/gql";
import {
	DeleteProcessMutation,
	DeleteProcessMutationVariables,
} from "~/types/__generated/gql/graphql";
import { getProcessesQueryKey, GetProcessesResults } from "../useGetProcesses";

const query = /* GraphQL */ `
	mutation DeleteProcess($id: ObjectID!) {
		deleteProcess(id: $id) {
			_id
		}
	}
`;

export type DeleteProcessVariables = DeleteProcessMutationVariables;
export type DeleteProcessResults = DeleteProcessMutation["deleteProcess"];

export const useDeleteProcess = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (variables: DeleteProcessVariables) =>
			apiGqlClient
				.request(graphql(query), variables)
				.then((res) => res.deleteProcess),
		onSuccess: (data) => {
			queryClient.setQueryData<GetProcessesResults>(
				getProcessesQueryKey(),
				(oldData) => (oldData ? oldData.filter((o) => o._id !== data._id) : []),
			);
		},
	});
};
