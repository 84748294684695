import { useMemo } from "react";

import { TableItem } from "~/components/products-table-summary";
import { useGetProductOptions } from "~/queries/useGetProductOptions";
import { useGetProducts } from "~/queries/useGetProducts";
import { useTillCreateFileInput, useTillUpdateFileInput } from "~/store";

export const useTillSummary = () => {
	const { data: products = [] } = useGetProducts();
	const { data: productOptions = [] } = useGetProductOptions();
	const createFileInput = useTillCreateFileInput();
	const updateFileInput = useTillUpdateFileInput();

	const createFileInputSummary = useMemo(() => {
		if (!createFileInput?.products.length) return { items: [], grandTotal: 0 };

		const items: TableItem[] = createFileInput.products.map((productInput) => ({
			id: productInput._id,
			name:
				products.find((p) => p._id === productInput.product)?.name ??
				"**UNKNOWN PRODUCT**",
			quantity: productInput.quantity,
			subtotal: productInput.quantity * productInput.metadata.listedPrice,
			options: productInput.options.map((optionInput) => ({
				id: optionInput._id,
				name:
					productOptions.find((o) => o._id === optionInput.option)?.name ??
					"**UNKNOWN PRODUCT OPTION**",
				quantity: optionInput.quantity,
				subtotal: optionInput.quantity * optionInput.metadata.listedPrice,
			})),
		}));
		return {
			items,
			grandTotal: items.reduce(
				(acc, item) =>
					acc +
					(item.subtotal +
						item.options.reduce(
							(acc, option) => acc + option.quantity * option.subtotal,
							0,
						)) *
						item.quantity,
				0,
			),
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [createFileInput]);

	const updateFileInputSummary = useMemo(() => {
		if (!updateFileInput?.products?.length) return { items: [], grandTotal: 0 };
		const items: TableItem[] = updateFileInput.products.map((productInput) => ({
			id: productInput._id,
			name:
				products.find((p) => p._id === productInput.product)?.name ??
				"**UNKNOWN PRODUCT**",
			quantity: productInput.quantity,
			subtotal: productInput.quantity * productInput.metadata.listedPrice,
			options: productInput.options.map((optionInput) => ({
				id: optionInput._id,
				name:
					productOptions.find((o) => o._id === optionInput.option)?.name ??
					"**UNKNOWN PRODUCT OPTION**",
				quantity: optionInput.quantity,
				subtotal: optionInput.quantity * optionInput.metadata.listedPrice,
			})),
		}));
		return {
			items,
			grandTotal: items.reduce(
				(acc, item) =>
					acc +
					(item.subtotal +
						item.options.reduce(
							(acc, option) => acc + option.quantity * option.subtotal,
							0,
						)) *
						item.quantity,
				0,
			),
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateFileInput]);

	return {
		createFileInputSummary,
		updateFileInputSummary,
	};
};
