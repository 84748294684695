import { useQuery } from "@tanstack/react-query";

import { apiGqlClient } from "~/libs/gql";
import { graphql } from "~/types/__generated/gql";
import { GetUsersQuery } from "~/types/__generated/gql/graphql";

const query = /* GraphQL */ `
	query GetUsers {
		getUsers {
			_id
			createdAt
			updatedAt
			firstName
			lastName
			phoneNumber
			email
			gender
			position
			department
			companyIssuedId
			role
			username
			avatar
			customFields {
				customField
				textValue
				selectValues
			}
		}
	}
`;

export type GetUsersResults = GetUsersQuery["getUsers"];

export const getUsersQueryKey = () => ["getUsers"];

export const useGetUsers = (enabled?: boolean) => {
	return useQuery({
		enabled: enabled ?? true,
		queryKey: getUsersQueryKey(),
		queryFn: async () =>
			apiGqlClient.request(graphql(query)).then((res) => res.getUsers),
	});
};

export const useGetUsersCache = () => {
	return useGetUsers(false);
};

export const getUsersCache = () => {
	// eslint-disable-next-line @typescript-eslint/unbound-method
	return window.$queryClient?.getQueryData<GetUsersResults>?.(
		getUsersQueryKey(),
	);
};
