import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import PlusIcon from "@untitled-ui/icons-react/build/esm/Plus";
import { FC } from "react";
import { useAppRouter } from "~/hooks/use-app-router";
import { useGetCurrentCompanyCache } from "~/queries/useGetCurrentCompany";
import { Process } from "~/types/__generated/gql/graphql";
interface FileCreateProps {
  process: Process;
}
export const FileCreate: FC<FileCreateProps> = ({
  process
}) => {
  const {
    data
  } = useGetCurrentCompanyCache();
  const {
    goToCreateFileQuery
  } = useAppRouter();
  const handleAddInit = () => {
    void goToCreateFileQuery(process._id);
  };
  return <Card sx={{
    backgroundColor: theme => theme.palette.mode === "dark" ? "neutral.800" : "background.paper"
  }} data-sentry-element="Card" data-sentry-component="FileCreate" data-sentry-source-file="file-create.tsx">
			<Stack alignItems="center" direction="row" onClick={handleAddInit} spacing={1} sx={{
      cursor: "pointer",
      p: 2,
      userSelect: "none"
    }} data-sentry-element="Stack" data-sentry-source-file="file-create.tsx">
				<SvgIcon color="action" data-sentry-element="SvgIcon" data-sentry-source-file="file-create.tsx">
					<PlusIcon data-sentry-element="PlusIcon" data-sentry-source-file="file-create.tsx" />
				</SvgIcon>
				<Typography color="text.secondary" variant="subtitle1" data-sentry-element="Typography" data-sentry-source-file="file-create.tsx">
					Create {data?.fileAlias?.singular}
				</Typography>
			</Stack>
		</Card>;
};